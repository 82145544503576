.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.curved-text-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
